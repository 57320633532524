import React, { useRef, useEffect } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'
import { T } from '../../typo'
import { s, colors, alpha, globals } from '../../style'
import { WrapperSection } from '../../wrappers'
import { string_to_slug } from '../../helpers'
import SideLine from '../../elements/SideLine/SideLine'
import ScrollContainer from 'react-indiana-drag-scroll'
import { ScrollDots } from '../../elements'

const Careers = ({ theme, data, ...props }) => {
  const { slug_section, title } = data
  const query = useStaticQuery(graphql`
    {
      career: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/careersOffers/" } }
      ) {
        edges {
          node {
            frontmatter {
              title
              city
              description
              # subtitle
              language
              slug_section
              seo_title
              seo_desc
              image {
                name
                publicURL
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, toFormat: JPG) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              responsibilities {
                resposnsibility
              }
              requirements {
                requirement
              }
              offers {
                offer
              }
            }
          }
        }
      }
    }
  `)

  // const authors = query.authors.edges.map(({ node }) => node.frontmatter)
  const careers = query.career.edges.map(({ node }) => node.frontmatter)
  const reverseCareers = careers.reverse()
  const refCareers = useRef(null)
  // const onScreen = useOnScreen(refCareers, '0px')

  useEffect(() => {
    if (refCareers.current)
      setTimeout(() => refCareers?.current?.scrollTo({ left: 1 }), 0)
  }, [])
  // console.log(authors)
  // console.log(careers)

  return (
    <>
      <WrapperSection
        id={slug_section}
        noPaddingTop
        noMarginTop
        outside={<SideLine theme={theme}>{title}</SideLine>}></WrapperSection>

      <div css={sBlogs}>
        <ScrollContainer
          nativeMobileScroll
          innerRef={refCareers}
          css={[
            sBlogsContainer,
            // { [s.md]: { width: features.length * 460 } },
            // features.length <= 3
            //   ? { [s.md]: { justifyContent: 'center' } }
            //   : { [s.md]: { marginLeft: 100 } },
          ]}>
          {reverseCareers
            .sort((a, b) => b.date - a.date)
            .filter((career) => career.image)
            .map((career, id) => (
              <div css={{ padding: '0 1.5rem' }} key={id}>
                <Link
                  to={`/${
                    career.slug_section
                      ? career.slug_section
                      : string_to_slug(career.title)
                  }`}
                  key={id}
                  css={[
                    sBlog,
                    sBlogTheme(theme),
                    // !career.slug_section && { filter: 'grayscale(1)', opacity: 0.3 },
                  ]}>
                  <div css={sImage}>
                    {career.image?.childImageSharp?.fluid && (
                      <Img
                        style={sImage.image}
                        fluid={career.image.childImageSharp.fluid}
                      />
                    )}
                  </div>
                  <div css={sTitle}>{career.title}</div>
                  <div css={sDescription}>{career.city}</div>
                </Link>
              </div>
            ))}
        </ScrollContainer>
      </div>

      <ScrollDots
        refElement={refCareers}
        arr={careers}
        extraCss={sDots(theme)}
      />
    </>
  )
}

const sBlogs = {
  display: 'flex',
  overflowX: 'auto',
  padding: '2rem 0rem',
  // overflowY: 'hidden',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'stretch',
}

const sHeadline = {
  [s.sm_down]: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '0rem',
  },
  [s.md]: { padding: '0 2rem', marginBottom: '2rem' },
}

const sBlog = {
  transition: 'transform 0.25s ease-out, box-shadow 0.25s ease-out',
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'stretch',
  flexShrink: 0,
  width: 366,
  height: '100%',
  borderRadius: 16,
  [s.xs]: {
    width: `calc(100vw - ${globals.spacing.inside.mobile * 2}px)`,
  },
  boxShadow: `0px 8px 16px ${colors.black.concat(alpha[4])}`,
  '&:hover': {
    [s.hover]: {
      transform: 'scale(1.05) translateY(-16px)',
      boxShadow: `0px 8px 32px ${colors.black.concat(alpha[16])}`,
    },
  },
}

const sBlogTheme = (theme) => ({
  backgroundColor: colors[theme].container.concat(alpha[30]),
})
const sDots = (theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 0 2rem',
  },
  dot: {
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: colors[theme].main,
  },
  inactive: {
    cursor: 'pointer',
    transition: 'opacity 0.2s ease-in-out',
    opacity: 0.16,
    padding: `0 6px`,
    '&:hover': {
      [s.hover]: {
        opacity: 0.6,
      },
    },
  },
  active: {
    [s.sm_down]: {
      opacity: 1,
      '&:hover': {
        [s.hover]: {
          opacity: 1,
        },
      },
    },
  },
  activeDesktop: {
    [s.md]: {
      opacity: 1,
      '&:hover': {
        [s.hover]: {
          opacity: 1,
        },
      },
    },
  },
})
const sImage = {
  position: 'relative',
  width: '100%',
  height: 200,
  borderRadius: '16px 16px 0 0',

  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
  },
}

const sTitle = {
  fontSize: 16,
  letterSpacing: -0.3,
  minHeight: 44,
  fontWeight: 700,
  margin: '1.5rem 1.5rem 0.5rem',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
}
const sBlogsContainer = {
  display: 'flex',
  overflowX: 'auto',
  [s.md]: { padding: '3rem 2rem 4rem' },
  // overflowY: 'hidden',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'stretch',
}
const sDescription = {
  fontSize: 18,
  letterSpacing: 0,
  fontWeight: 700,
  margin: '0rem 1.5rem 1.5rem',
  opacity: 0.3,
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
}

export default Careers
